import {NavigationType, useLocation, useNavigationType} from '@remix-run/react';
import {usePrevious} from '@shopify/react-hooks';
import React, {createContext, useRef} from 'react';

export const PreviousRouteInfoContext = createContext<string | undefined>(
  undefined,
);

interface PreviousRouteInfoProviderProps {
  children: React.ReactNode;
}

export function PreviousRouteInfoProvider({
  children,
}: PreviousRouteInfoProviderProps) {
  const navigationType = useNavigationType();
  const {pathname, search} = useLocation();
  const returnToRef = useRef<string>();

  const fullUrl = `${pathname}${search}`;
  const previousUrl = usePrevious(fullUrl);
  const wasReplaced = navigationType === NavigationType.Replace;

  // if the history was replaced, we want to keep the previous url
  // this mimics what the browser's history stack will do if you use the back button
  // Some times when blocking navigation from the browser, history.push is called anyway with the same url
  // using the previousUrl in that case creates a navigation loop
  const returnTo =
    wasReplaced || fullUrl === previousUrl ? returnToRef.current : previousUrl;
  returnToRef.current = returnTo;

  return (
    <PreviousRouteInfoContext.Provider value={returnTo}>
      {children}
    </PreviousRouteInfoContext.Provider>
  );
}
